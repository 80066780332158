.navbar {
  position: fixed !important; //sticky;
  bottom: 0 !important;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw !important;
  height: 2.5em;
  margin-bottom: 0;
  background-color: #070e68; //#525252;
  color: white;
  line-height: 1rem !important;
  font-size: 1.3rem;
   
  .nav-home {
    width: 5em !important;
    padding-left: 0.5em;
  }
  
  .nav-wallet-button {
    width: 5em;
    padding-right: 0.5em;
    text-align: right;

  }
  
  .active {
    color: #70a3d2 !important;
  }

  .nav-action {
    font-size: 1.3rem;
    text-align: center;
  }

  .nav-subscribe-button {
    cursor: pointer;
    border: 1px solid white;
    border-radius: 10px;
    background-color: transparent;
    color: white;
    padding: 0.4em 1.2em 0.4em;
    font-size: 0.9rem;
  }
}