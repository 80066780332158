.domain-record {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
    
  .record-title {
    font-size: 1.2rem;
  }
  .record-owner {
    font-size: 0.8rem;
    color: rgb(29, 29, 115);
  }
}  